import $ from 'jquery'
import Swiper from 'swiper'
import scrollWatch from '@/assets/scrollwatch.js'
import '@/assets/swiper.min.css'
import Cookie from 'js-cookie'

// 部署配置
 
const isMock = false
const url = isMock ? 'http://api-doc.mt.yy.com/mock/13/cloud' : 'https://'+ window.mainDomain +'/cloud'

function toogleLgnAndReg (flag) {
  $('.header-login').css('display', flag ? 'block' : 'none')
  $('.header-register').css('display', flag ? 'block' : 'none')
}

$('.Jlang').on('click', function() {
  const langCode = $(this).data('lang');
  window.location.href = /\/(cn|en)(\/|$)/g.test(window.location.href) ? window.location.href.replace(/\/(cn|en)(\/|$)/g, '/'+ langCode +'/') : (window.location.href +'/'+ langCode);
});

function getAccountStatus () {
  $.ajax({
    url: url + '/account/status',
    type: 'GET',
    xhrFields: {
      withCredentials: true
    },
    crossDomain: true,
    success: (res) => {
      
      if (res.code == '0' && res.data.isLogin) {
        Cookie.set('isLogin', 'true');

        if(/\/login$/.test(location.pathname)) {
          location.href = '/';
          return;
        }
        getUserInfo();
      } else {
        loginOutAndToogleLgnAndReg();
      }
    },
    error: (error) => {
      loginOutAndToogleLgnAndReg();
    }
  })
}
function getUserInfo () {
  $.ajax({
    url: url + '/account/userInfo',
    type: 'GET',
    xhrFields: {
      withCredentials: true
    },
    crossDomain: true,
    success: (res) => {
      if (res.code && res.data) {
        toogleLgnAndReg(false)
        const name = res.data.name || res.data.email || res.data.uid
        $('.user-name').text(name)
        $('.user-name').attr('title', name)
        $('.header-user').css('display', 'block')
      } else {
        toogleLgnAndReg(true);
      }
    },
    error: (error) => {
      toogleLgnAndReg(true);
    }
  })
}

function loginOut (cb) {
  $.ajax({
    url: url + '/account/loginout',
    type: 'GET',
    xhrFields: {
      withCredentials: true
    },
    crossDomain: true,
    success: (res) => {
      cb && cb();
      if (res.code) {
        $('.header-login').css('display', 'block')
        $('.header-register').css('display', 'block')
        $('.user-name').text('')
        $('.user-name').attr('title', '')
        $('.header-user').css('display', 'none')

      }
    },
    error: (error) => {
      cb && cb();
    }
  })
}
function loginOutAndToogleLgnAndReg() {
  loginOut(() => {
    Cookie.set('isLogin', false);
    toogleLgnAndReg(true)
  });
}
new scrollWatch();

const sceneeSwiper = new Swiper('.scene-swiper', {
  autoplay: false,
  effect: 'fade'
})

$('.scene-tab li').mouseenter(function (e) {
  const index = $('.scene-tab li').index(this)
  $(this).siblings('li').children('span').removeClass('scene-tab__active')
  $(this).children('span').addClass('scene-tab__active')
  sceneeSwiper.slideTo(index, 300, false)
})

$('.header-loginout').click(() => {
  loginOut()
})

getAccountStatus()
